import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PartnerLeadHistory } from '../../../models/partners/partner-lead-history';
import { LeadHistory } from '../../../models/lead-history';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { Application } from '../../../enum-collection';

@Component({
  selector: 'lib-feed-status-history',
  templateUrl: './feed-status-history.component.html',
  styleUrls: ['./feed-status-history.component.scss'],
})
export class FeedStatusHistoryComponent implements OnInit, OnDestroy {
  @Input() partnerLeadHistory: PartnerLeadHistory = new PartnerLeadHistory();
  @Input() leadHistory: LeadHistory = new LeadHistory();

  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Application = Application;
}
