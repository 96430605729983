<app-card>
  <app-card-head pageTitle="Erinnerungen">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-stroked-button color="primary" (click)="navigateToLeads()">
        Alle Leads anzeigen
      </button>

      <lib-filter-bar [filterSettings]="filterBarSettings"></lib-filter-bar>
    </div>
  </app-card-head>
  <app-card-body>
    <lib-table
      [tableId]="'next-best-action-list'"
      [initialPageSize]="50"
      [filter]="filter"
      [endpoint]="'reminders/user_reminder'"
    >
      <lib-table-column
        [isSortable]="false"
        [isSearchable]="false"
        [defaultSort]="'asc'"
        [columnName]="'reminder_date'"
        [title]="'Erinnerungsdatum'"
        [backgroundColorFn]="getBackgroundColor"
        [customTemplate]="reminderTimeTemplate"
      >
        <ng-template #reminderTimeTemplate let-reminder>
          {{ formatDate(reminder.reminder_date) }}
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [title]="'Leadnummer'"
        [isSortable]="false"
        [isSearchable]="false"
        [columnName]="'partner_lead.lead_number'"
        [customTemplate]="leadNumberTemplate"
      >
        <ng-template #leadNumberTemplate let-reminder>
          <a style="cursor: pointer" (click)="openLead(reminder)">{{
            reminder.partner_lead.lead_number
          }}</a>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'reminder_type_option.name'"
        [title]="'Erinnerungsgrund'"
        [isSortable]="false"
        [isSearchable]="false"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'partner_lead.lead_type.name'"
        [title]="'Leadart'"
        [isSortable]="false"
        [isSearchable]="false"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'partner_user.last_name'"
        [title]="'Benutzer'"
        [isSortable]="false"
        [isSearchable]="false"
        [customTemplate]="leadUserTemplate"
      >
        <ng-template #leadUserTemplate let-reminder>
          {{ reminder.partner_user?.first_name }}
          {{ reminder.partner_user?.last_name }}
        </ng-template></lib-table-column
      >

      <lib-table-column
        [title]="'Status'"
        [columnName]="'status'"
        [isSortable]="false"
        [isSearchable]="false"
        [customTemplate]="leadStatusTemplate"
      >
        <ng-template #leadStatusTemplate let-reminder>
          <lib-partner-lead-status-badge
            [status]="reminder.partner_lead.status_option"
          ></lib-partner-lead-status-badge>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [title]="'Firma'"
        [columnName]="'address.company_name'"
        [isSortable]="false"
        [isSearchable]="false"
        [customTemplate]="leadCompanyTemplate"
      >
        <ng-template #leadCompanyTemplate let-reminder>
          <ng-container *ngIf="reminder.partner_lead.address">
            <span class="fw-bold">{{
              reminder.partner_lead.address.company_name
            }}</span>
            <br />
            <ng-container
              *ngIf="
                getInvoiceAddressFromAddresses(
                  reminder.partner_lead.addresses
                ) &&
                getInvoiceAddressFromAddresses(reminder.partner_lead.addresses)
                  ?.company_name !== ''
              "
            >
              Abw. Firma:
              <span class="fw-bold">{{
                getInvoiceAddressFromAddresses(reminder.partner_lead.addresses)
                  ?.company_name
              }}</span>
            </ng-container>
          </ng-container>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'partner_lead.address.name'"
        [title]="'Zusammenfassung'"
        [isSortable]="false"
        [isSearchable]="false"
        [customTemplate]="leadSummaryTemplate"
      >
        <ng-template #leadSummaryTemplate let-reminder>
          <div style="padding: 10px">
            <ng-container
              *ngIf="
                reminder.partner_lead?.comment_summaries?.length > 0;
                else noSummary
              "
            >
              <div class="lead-list-entry-margin" style="max-width: 500px">
                {{ reminder.partner_lead.comment_summaries[0].summary }}
              </div>
            </ng-container>

            <ng-template #noSummary>
              <div class="lead-list-entry-margin" style="max-width: 500px">
                <em>Keine Zusammenfassung vorhanden</em>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [title]="'Projektstandort'"
        [isSortable]="false"
        [isSearchable]="false"
        [columnName]="'address.postcode'"
        [customTemplate]="leadAddressTemplate"
      >
        <ng-template #leadAddressTemplate let-reminder>
          <ng-container *ngIf="reminder.partner_lead.address">
            {{ reminder.partner_lead.address.postcode }}
            {{ reminder.partner_lead.address.city }}
          </ng-container>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [title]="'Aktion'"
        [isSortable]="false"
        [isSearchable]="false"
        [columnName]="'action'"
        [customTemplate]="reminderActionTemplate"
      >
        <ng-template #reminderActionTemplate let-reminder>
          <div style="padding: 10px">
            <button
              color="primary"
              mat-stroked-button
              (click)="markReminderAsDone(reminder)"
            >
              Als erledigt markieren
            </button>
          </div>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
