<div fxLayout="column">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="8px"
    *ngIf="commentWebsite.lead_comment"
  >
    <mat-slide-toggle [(ngModel)]="showDiff"
      >Änderungserkennung verwenden</mat-slide-toggle
    >

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        class="action-button"
        mat-icon-button
        color="primary"
        (click)="copyToClipboard(commentWebsite.lead_comment)"
        matTooltip="Kommentar vom Lead kopieren"
      >
        <mat-icon>content_copy</mat-icon>
      </button>

      <div
        class="custom-badge badge-red"
        *ngIf="getFeedSalesrunnerVisibilityHint()"
      >
        <span [innerHTML]="getFeedSalesrunnerVisibilityHint()"></span>
      </div>
    </div>
  </div>

  <div *ngIf="!showDiff" fxLayout="column" fxLayoutGap="8px">
    <div>
      <b>Original:</b>
      <p class="pre-line">
        {{ commentWebsite.original_ai_comment }}
      </p>
    </div>
    <div>
      <b>Lead:</b>
      <p class="pre-line" *ngIf="!commentWebsite.lead_comment">
        <em>Lead hat keine Änderungen am KI Text vorgenommen.</em>
      </p>
      <p class="pre-line" *ngIf="commentWebsite.lead_comment">
        {{ commentWebsite.lead_comment }}
      </p>
    </div>
  </div>

  <div *ngIf="showDiff" class="diff-view">
    <span *ngFor="let part of diffParts" [class]="part.type">{{
      part.text
    }}</span>
  </div>
</div>
