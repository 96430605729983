import { Injectable } from '@angular/core';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiagramService {
  endpoint = 'revenue_timeline';
  endpointLeadStatus = 'lead_status';
  endpointLeadsReceived = 'leads_received_bar_chart';

  constructor(private httpHandler: HttpHandlerService) {}

  revenueTimeline(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  leadStatus(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list(this.endpointLeadStatus, httpParams)
      .pipe(map((response) => response.data));
  }

  leadsReceived(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list(this.endpointLeadsReceived, httpParams)
      .pipe(map((response) => response.data));
  }

  lossReasons(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list('loss_reasons', httpParams)
      .pipe(map((response) => response.data));
  }
}
