import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@lib/services/storage.service';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LeadStateService } from './lead-state.service';

@Injectable({
  providedIn: 'root',
})
export class LeadListViewService {
  static readonly VIEW_STORAGE_KEY = 'lead_list_view';
  private useNewViewSubject = new BehaviorSubject<number>(0);
  useNewView$ = this.useNewViewSubject.asObservable();

  constructor(
    private storageService: StorageService,
    private router: Router,
    private leadStateService: LeadStateService,
  ) {
    this.updateViewFromUrl(this.router.url);

    this.router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.updateViewFromUrl(event.url);
      });
  }

  private updateViewFromUrl(url: string) {
    const storedView = this.storageService.getFromFromLocalStorage(
      LeadListViewService.VIEW_STORAGE_KEY,
    );

    // Wenn ein gespeicherter Wert existiert, nutze diesen immer
    if (storedView !== null) {
      this.useNewViewSubject.next(storedView);
      if (url.startsWith('/leads') || url.startsWith('/leads-new')) {
        const shouldBeNewView = storedView === 1;
        const currentIsNewView = url.startsWith('/leads-new');
        if (shouldBeNewView !== currentIsNewView) {
          const baseRoute = shouldBeNewView ? '/leads-new' : '/leads';
          // Remove query parameters when switching views
          this.router.navigate([baseRoute]);
        }
      }
    } else {
      // Nur wenn kein gespeicherter Wert existiert, nutze die URL
      const isNewView = url.startsWith('/leads-new');
      const newValue = isNewView ? 1 : 0;
      this.useNewViewSubject.next(newValue);
      this.storageService.setInLocalStorage(
        LeadListViewService.VIEW_STORAGE_KEY,
        newValue,
      );
    }
  }

  toggleView() {
    const newValue = this.useNewViewSubject.value === 1 ? 0 : 1;
    this.useNewViewSubject.next(newValue);
    this.storageService.setInLocalStorage(
      LeadListViewService.VIEW_STORAGE_KEY,
      newValue,
    );

    // Navigate to base route without query parameters
    const baseRoute = newValue === 1 ? '/leads-new' : '/leads';
    this.router.navigate([baseRoute]);
  }

  openLeadById(id: number) {
    if (this.useNewViewSubject.value === 1) {
      this.router.navigate(['/leads-new'], {
        queryParams: { id: id },
      });
    } else {
      this.router.navigate(['/leads/edit', id]);
    }
  }
}
