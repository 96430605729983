import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterSetting } from '@lib/models/filter-setting';
import { FilterService } from '@lib/services/filter/filter.service';

import { Subject, Subscription, takeUntil } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { MapDataService } from '@lib/services/map/map_data.service';
import { MapHeatmapPoint } from '@lib/interfaces/map.interface';
import { PartnerLead } from '@lib/models/partner-lead';
import { DiagramService } from '../../services/diagram.service';
import { DateHelper } from '@lib/helpers/date-helper';
import { PartnerUserService } from '@lib/services/partner/partner-user.service';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { PartnerUserCostType } from '@lib/enum-collection';
import { PerformanceTiles } from '../../models/performance-tiles-data';
import { AuthService } from '@lib/services/auth.service';
import { SelectList } from '@lib/models/select-list';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  partnerUser: PartnerUser | null = null;
  performanceTilesLoaded: boolean = false;
  revenueTimelineLoaded: boolean = false;
  leadStatusLoaded: boolean = false;
  leadReceivedLoaded: boolean = false;
  regionalLeadPlacementsLoaded: boolean = false;
  regionalSuccessLeadPlacementsLoaded: boolean = false;

  filter = {
    lead_type_id: [],
    partner_user_id: [],
    lead_details: null,
  };
  heatMapPoints: MapHeatmapPoint[] = [];
  heatMapPointSales: MapHeatmapPoint[] = [];
  leadFieldDashboardTables: any[] = [];
  revenueTimelineLabels: any[] = [];
  revenueTimelineData: {
    data: number[];
    label: string;
    borderColor: string;
    backgroundColor: string;
    yAxisID: string;
  }[] = [];
  leadReceivedLabels: any[] = [];
  leadReceivedData: {
    data: number[];
    label: string;
    borderColor: string;
    backgroundColor: string;
  }[] = [];
  daterange = { start: null as string | null, end: null as string | null };
  performanceTiles = PerformanceTiles;

  declinedDataLoaded = false;
  declinedLabels: string[] = [];
  declinedChartData: any[] = [];
  cancelledDataLoaded = false;
  cancelledLabels: string[] = [];
  cancelledChartData: any[] = [];

  filterBarSettings: FilterSetting[] = [];
  private filterSubscription: Subscription = new Subscription();
  leadStatusLabels = [
    'Neu',
    'Kontakt hergestellt',
    'Angebot versendet & Wiedervorlage',
    'Verloren',
    'Auftrag erhalten & Projekt aktiv',
    'Auftrag erhalten & Projekt beendet',
  ];
  leadStatusData: any = [0, 0, 0, 0, 0, 0];

  private destroy$ = new Subject<void>();

  constructor(
    private filterService: FilterService,
    private diagramService: DiagramService,
    private mapDataService: MapDataService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private partnerUserService: PartnerUserService,
  ) {
    this.filterSubscription = this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });
  }

  ngOnInit() {
    this.initializeUser();
    this.partnerUserService
      .getList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerUsers: PartnerUser[]) => {
        this.initFilter(partnerUsers?.length > 0);
      });
  }

  private initializeUser() {
    this.authService.partnerUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerUser: PartnerUser | null) => {
        this.partnerUser = partnerUser;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilter(withPartnerUsers: boolean) {
    if (withPartnerUsers) {
      this.filterBarSettings = [
        new FilterSetting({
          filter: SelectList.PartnerLeadDetail,
          multiSelect: true,
          placeholder: 'Lead Details',
        }),
        new FilterSetting({
          filter: SelectList.LeadType,
          multiSelect: true,
          placeholder: 'Lead-Art',
        }),
        new FilterSetting({
          filter: SelectList.DateRange,
          multiSelect: false,
        }),
        new FilterSetting({
          filter: SelectList.PartnerUser,
          multiSelect: true,
          placeholder: 'User',
        }),
      ];
    } else {
      this.filterBarSettings = [
        new FilterSetting({
          filter: SelectList.PartnerLeadDetail,
          placeholder: 'Lead Details',
          multiSelect: true,
        }),
        new FilterSetting({
          filter: SelectList.LeadType,
          placeholder: 'Lead-Art',
          multiSelect: true,
        }),
        new FilterSetting({
          filter: SelectList.DateRange,
          multiSelect: false,
        }),
      ];
    }

    this.filterService.setDaterange({
      start: DateHelper.getOneYearAgo(),
      end: new Date(),
    });

    this.filterService.getFilter();
  }

  setFilter(filter: any) {
    this.filter = {
      ...this.filter, // Kopiert alle vorhandenen Filter
      partner_user_id: filter.filter?.partnerUser,
      lead_type_id: filter.filter?.leadTypeId,
      lead_details: filter.filter?.partnerLeadDetail,
    };

    Object.assign(this.daterange, filter.daterange);

    this.getPerformanceTiles();
    this.getLeadMapData();
    this.getSaleMapData();
    this.getLeadFieldDashboardTables();
    this.getRevenueTimelines();
    this.getLeadStatusChart();
    this.getLeadReceivedChart();
    this.getLossReasons();
  }

  getRevenueTimelines() {
    this.diagramService
      .revenueTimeline({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.revenueTimelineLabels = response.timeline;
          this.revenueTimelineData = [
            {
              data: response.revenue,
              label: 'Umsatz',
              borderColor: '#01579B',
              backgroundColor: '#01579B',
              yAxisID: 'y',
            },
            {
              data: response.acceptance_rate,
              label: 'Annahmequote',
              borderColor: '#2E7D32',
              backgroundColor: '#2E7D32',
              yAxisID: 'percentage',
            },
            {
              data: response.success_rate,
              label: 'Abschlussquote',
              borderColor: '#EF6C00',
              backgroundColor: '#EF6C00',
              yAxisID: 'percentage',
            },
          ];
          this.revenueTimelineLoaded = true;
        }
      });
  }

  getLossReasons() {
    this.diagramService
      .lossReasons({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          // Stornierungsgründe
          this.cancelledLabels = response.cancelled.map(
            (item: any) => item.reason,
          );
          this.cancelledChartData = [
            {
              data: response.cancelled.map((item: any) => item.count),
              label: 'Anzahl',
              backgroundColor: '#E57373',
              borderColor: '#E57373',
            },
          ];
          this.cancelledDataLoaded = true;

          // Ablehnungsgründe
          this.declinedLabels = response.declined.map(
            (item: any) => item.reason,
          );
          this.declinedChartData = [
            {
              data: response.declined.map((item: any) => item.count),
              label: 'Anzahl',
              backgroundColor: '#FFB74D',
              borderColor: '#FFB74D',
            },
          ];

          this.declinedDataLoaded = true;
        }
      });
  }

  getLeadStatusChart() {
    this.diagramService
      .leadStatus({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.leadStatusLoaded = true;
          this.leadStatusData = Object.values(response);
        }
      });
  }

  getLeadReceivedChart() {
    this.diagramService
      .leadsReceived({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response?.months) {
          this.leadReceivedLabels = response.months;
          this.leadReceivedData = [
            {
              data: response.total_leads,
              label: 'Leads gesendet',
              borderColor: '#01579B',
              backgroundColor: '#01579B',
            },
            {
              data: response.accepted_leads,
              label: 'Leads angenommen',
              borderColor: '#2E7D32',
              backgroundColor: '#2E7D32',
            },
          ];
          this.leadReceivedLoaded = true;
        }
      });
  }

  getLeadMapData() {
    this.mapDataService
      .leadsForMap({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeads: PartnerLead[]) => {
        if (partnerLeads) {
          this.regionalLeadPlacementsLoaded = true;

          this.heatMapPoints = partnerLeads.map((partnerLead: PartnerLead) => {
            return {
              coordinates: [partnerLead.address.lng, partnerLead.address.lat],
            };
          });
        }
      });
  }

  getSaleMapData() {
    this.mapDataService
      .salesForMap({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeads: PartnerLead[]) => {
        if (partnerLeads) {
          this.regionalSuccessLeadPlacementsLoaded = true;

          this.heatMapPointSales = partnerLeads.map(
            (partnerLead: PartnerLead) => {
              return {
                coordinates: [partnerLead.address.lng, partnerLead.address.lat],
              };
            },
          );
        }
      });
  }

  getLeadFieldDashboardTables() {
    this.dashboardService
      .leadFieldDashboard({
        filter: this.filter,
        daterange: this.daterange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.leadFieldDashboardTables = response;
        }
      });
  }

  getPerformanceTiles() {
    this.dashboardService
      .list({ filter: this.filter, daterange: this.daterange })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.performanceTiles.forEach((title: any) => {
            if (title.name == 'success_quote') {
              title.ranking = response['success_quote_ranking'];
            }
            if (title.name == 'accepted_rate') {
              title.ranking = response['accepted_rate_ranking'];
            }
            if (title.name == 'reaction_time_median') {
              title.ranking = response['reaction_time_ranking'];
            }
            if (
              title.name == 'revenue' ||
              title.name == 'accepted_rate' ||
              title.name == 'reaction_time_median'
            ) {
              title.hide = this.partnerUser?.invoice_and_performance_data === 1;
            }

            title.value = response[title.name] ? response[title.name] : 0;
          });

          // Bei Pay-Per-Lead sollen nicht alle Grafiken gezeigt werden
          if (this.partnerUser?.cost_type === PartnerUserCostType.Lead) {
            this.performanceTiles = this.performanceTiles.filter(
              (tile) =>
                tile.name !== 'accepted_rate' &&
                tile.name !== 'revenue' &&
                tile.name !== 'reaction_time_median',
            );
          }

          this.performanceTilesLoaded = true;
        }
      });
  }

  getRankingTrophy(ranking: number) {
    if (ranking == 1) {
      return './assets/img/ranking/trophy1.png';
    }
    if (ranking == 2) {
      return './assets/img/ranking/trophy2.png';
    }
    if (ranking == 3) {
      return './assets/img/ranking/trophy3.png';
    }
    return '';
  }

  protected readonly PartnerUserCostType = PartnerUserCostType;
}
