import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, Subject, takeUntil, take } from 'rxjs';
import { NewSelectOption } from '../../models/new-select-option';
import { NewSelectSelection } from '../../models/new-select-selection';
import { Condition } from '../../models/condition';
import { SelectList } from '../../models/select-list';
import { SelectService } from '../../services/select.service';

enum HelperType {
  LeadType = 'leadTypeHelper',
  EmailTemplate = 'emailTemplateHelper',
  LeadDetails = 'leadDetailsHelper',
}

interface LeadDetailValueOption {
  smartTag: string;
  options: NewSelectOption[];
}

interface ConditionState {
  property: NewSelectOption | null;
  operator: NewSelectOption | null;
  leadType: NewSelectOption | null;
  emailTemplate: NewSelectOption | null;
  leadDetails: NewSelectOption | null;
  value: string;
  smartTagOptions: LeadDetailValueOption[] | null;
}
@Component({
  selector: 'lib-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionComponent implements OnInit, OnDestroy {
  @Input() propertyOptions: NewSelectOption[] | null = [];
  @Input() leadDetailValueOptions: LeadDetailValueOption[] | null = [];
  @Input() operatorOptions: NewSelectOption[] | null = [];
  @Input() set condition(value: Condition) {
    if (JSON.stringify(this._condition) !== JSON.stringify(value)) {
      this._condition = value;
      this.initializeSelections();
    }
  }
  @Output() conditionChange = new EventEmitter<Condition>();

  readonly HelperType = HelperType;
  readonly valueControl: FormControl;

  leadTypeList$: Observable<NewSelectOption[]>;
  emailTemplateList$: Observable<NewSelectOption[]>;

  filteredOperatorOptions: NewSelectOption[] | null = null;

  private _condition!: Condition;
  private readonly destroy$ = new Subject<void>();
  private readonly state: ConditionState = {
    property: null,
    operator: null,
    leadType: null,
    emailTemplate: null,
    leadDetails: null,
    value: '',
    smartTagOptions: null,
  };

  constructor(
    private selectService: SelectService,
    private cd: ChangeDetectorRef,
  ) {
    this.valueControl = new FormControl({
      value: '',
      disabled: this.shouldDisableValueControl(),
    });

    this.leadTypeList$ = this.selectService
      .getSelectOptionsBySelectList(SelectList.LeadType)
      .pipe(takeUntil(this.destroy$));

    this.emailTemplateList$ = this.selectService
      .getSelectOptionsBySelectList(SelectList.EmailType)
      .pipe(takeUntil(this.destroy$));

    this.valueControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.onValueChanged(value || ''));
  }

  ngOnInit(): void {
    this.initializeSelections();

    // Verzögerte Initialisierung um sicherzustellen, dass alles geladen ist
    setTimeout(() => {
      if (this.shouldDisableValueControl() && this.state.operator) {
        const isValidOperator = this.filteredOperatorOptions?.some(
          (op) => op.value === this.state.operator?.value,
        );

        if (!isValidOperator) {
          const defaultOperator = this.filteredOperatorOptions?.find(
            (op) => op.value === '==',
          );
          if (defaultOperator) {
            // Explizit ein NewSelectSelection Objekt erstellen
            const selection = new NewSelectSelection();
            selection.options = [defaultOperator];
            selection.isNegative = false;

            this.onOperatorChanged(selection);
          }
        }
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get selectedProperty(): NewSelectOption | null {
    return this.state.property;
  }

  get selectedOperator(): NewSelectOption | null {
    return this.state.operator;
  }

  get selectedLeadType(): NewSelectOption | null {
    return this.state.leadType;
  }

  get selectedEmailTemplate(): NewSelectOption | null {
    return this.state.emailTemplate;
  }

  get selectedLeadDetails(): NewSelectOption | null {
    return this.state.leadDetails;
  }

  private updateFilteredOperatorOptions(): void {
    if (!this.operatorOptions) {
      this.filteredOperatorOptions = null;
      return;
    }

    if (
      this.showValuesAsSelect(HelperType.LeadType) ||
      this.showValuesAsSelect(HelperType.EmailTemplate) ||
      this.showValuesAsSelect(HelperType.LeadDetails)
    ) {
      this.filteredOperatorOptions = this.operatorOptions.filter(
        (op) => op.value === '==' || op.value === '!=',
      );
    } else {
      this.filteredOperatorOptions = this.operatorOptions;
    }
  }

  private initializeSelections(): void {
    if (!this._condition) return;

    this.state.property = this.findSelectOption(
      this._condition.property,
      this.propertyOptions,
    );

    this.updateFilteredOperatorOptions();

    if (this.shouldDisableValueControl()) {
      const validOperators = this.filteredOperatorOptions;
      const currentOperator = this.findSelectOption(
        this._condition.operator,
        validOperators,
      );
      this.state.operator = currentOperator || validOperators?.[0] || null;
    } else {
      this.state.operator = this.findSelectOption(
        this._condition.operator,
        this.operatorOptions,
      );
    }

    if (this._condition.property === 'lead_type_id') {
      this.leadTypeList$.pipe(take(1)).subscribe((options) => {
        const option = options.find(
          (opt) => opt.value == this._condition.value,
        );
        if (option) {
          this.state.leadType = option;
          this.state.emailTemplate = null;
          this.state.leadDetails = null;
        }
      });
    } else if (this._condition.property === 'email_type_id') {
      this.emailTemplateList$.pipe(take(1)).subscribe((options) => {
        const option = options.find(
          (opt) => opt.value == this._condition.value,
        );
        if (option) {
          this.state.emailTemplate = option;
          this.state.leadType = null;
          this.state.leadDetails = null;
        }
      });
    } else if (this._condition.property.includes('Lead->Details')) {
      const matchingTag = this.leadDetailValueOptions?.find(
        (option) => option.smartTag === this._condition.property,
      );

      if (matchingTag) {
        const selectedOption = matchingTag.options.find(
          (opt) => opt.value == this._condition.value,
        );
        if (selectedOption) {
          this.state.leadDetails = selectedOption;
          this.state.leadType = null;
          this.state.emailTemplate = null;
        }
      }
    } else {
      this.state.leadType = null;
      this.state.emailTemplate = null;
      this.state.leadDetails = null;
      this.valueControl.setValue(this._condition.value, { emitEvent: false });
    }

    const shouldBeDisabled = this.shouldDisableValueControl();
    if (shouldBeDisabled) {
      this.valueControl.disable({ emitEvent: false });
    } else {
      this.valueControl.enable({ emitEvent: false });
    }
  }

  onPropertyChanged(event: NewSelectSelection): void {
    const selectedOption = event.options[0];
    this.state.property = selectedOption;
    this.state.leadType = null;
    this.state.emailTemplate = null;
    this.state.leadDetails = null;

    this.updateFilteredOperatorOptions();

    const shouldBeDisabled = this.shouldDisableValueControl();
    if (shouldBeDisabled) {
      this.valueControl.disable({ emitEvent: false });

      const defaultOperator = this.filteredOperatorOptions?.find(
        (op) => op.value === '==',
      );
      if (defaultOperator) {
        this.state.operator = defaultOperator;
        this.emitConditionChange({
          property: selectedOption.value,
          operator: defaultOperator.value,
          value: '',
        });
        return;
      }
    } else {
      this.valueControl.enable({ emitEvent: false });
    }

    this.emitConditionChange({
      property: selectedOption.value,
      value: '',
    });
  }

  onOperatorChanged(event: NewSelectSelection): void {
    const selectedOption = event.options[0];

    if (this.shouldDisableValueControl()) {
      const isValidOperator = this.filteredOperatorOptions?.some(
        (op) => op.value === selectedOption.value,
      );
      if (!isValidOperator) {
        console.warn('Invalid operator selected for current state');
        return;
      }
    }

    // Update state nur wenn sich wirklich was geändert hat
    if (this.state.operator?.value !== selectedOption.value) {
      this.state.operator = selectedOption;
      this.emitConditionChange({
        operator: selectedOption.value,
      });
    }
  }

  onValueChanged(value: string): void {
    this.emitConditionChange({ value });
  }

  onValueSelectChanged(
    event: NewSelectSelection,
    helperType: HelperType,
  ): void {
    const selectedOption = event.options[0];

    switch (helperType) {
      case HelperType.LeadType:
        this.state.leadType = selectedOption;
        this.state.emailTemplate = null;
        this.state.leadDetails = null;
        break;
      case HelperType.EmailTemplate:
        this.state.emailTemplate = selectedOption;
        this.state.leadType = null;
        this.state.leadDetails = null;
        break;
      case HelperType.LeadDetails:
        this.state.leadDetails = selectedOption;
        this.state.leadType = null;
        this.state.emailTemplate = null;
        break;
    }

    this.emitConditionChange({
      value: selectedOption.value,
    });
  }

  showValuesAsSelect(helperType: HelperType): boolean {
    if (!this.state.property) return false;

    return (
      (helperType === HelperType.LeadType &&
        this.state.property.value === 'lead_type_id') ||
      (helperType === HelperType.EmailTemplate &&
        this.state.property.value === 'email_type_id') ||
      (helperType === HelperType.LeadDetails &&
        this.state.property.value.includes('Lead->Details') &&
        this.hasMatchingLeadDetailOptions())
    );
  }

  private shouldDisableValueControl(): boolean {
    return (
      this.showValuesAsSelect(HelperType.LeadType) ||
      this.showValuesAsSelect(HelperType.EmailTemplate) ||
      this.showValuesAsSelect(HelperType.LeadDetails)
    );
  }
  private findSelectOption(
    value: string,
    options: NewSelectOption[] | null,
  ): NewSelectOption | null {
    return options?.find((opt) => opt.value === value) || null;
  }
  private hasMatchingLeadDetailOptions(): boolean {
    return !!this.leadDetailValueOptions?.some(
      (option) => option.smartTag === this.state.property?.value,
    );
  }
  private emitConditionChange(partialCondition: Partial<Condition>): void {
    this.conditionChange.emit({
      ...this._condition,
      ...partialCondition,
    });
  }

  get currentDetailOptions(): NewSelectOption[] {
    const matchingTag = this.leadDetailValueOptions?.find(
      (option) => option.smartTag === this.state.property?.value,
    );
    return matchingTag?.options || [];
  }
}
