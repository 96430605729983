import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterOutlet } from '@angular/router';
import { PartnerUsersComponent } from './components/partner-users/partner-users.component';
import { PartnerBillsComponent } from './components/partner-bill/partner-bills/partner-bills.component';
import { PartnerBillDeleteModalComponent } from './components/partner-bill/partner-bill-delete-modal/partner-bill-delete-modal.component';
import { LeadListComponent } from './components/leads/lead-list.component';
import { PartnerLeadOfferValueComponent } from './components/leads/partner-lead-offer-value.component';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@lib/shared.module';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe, NgForOf, NgIf, registerLocaleData } from '@angular/common';
import { PartnerBillFileDeleteComponent } from './components/partner-bill/partner-bill-file-delete/partner-bill-file-delete.component';
import { TaskBoardComponent } from './components/task-board/task-board.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PartnerBillFormComponent } from './components/partner-bill/partner-bill-form/partner-bill-form.component';
import { LeadEditComponent } from './components/leads/lead-edit/lead-edit.component';
import { PartnerLeadBackendComponent } from './components/leads/partner-lead-backend/partner-lead-backend.component';
import { ChanceListComponent } from './components/chances/chance-list.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TargetingListComponent } from './components/targeting/targeting-list.component';
import { EditTargetingDetailModalComponent } from './components/targeting/edit-targeting-detail-modal/edit-targeting-detail-modal.component';
import localeDe from '@angular/common/locales/de';
import { AuthInterceptor } from '@lib/auth.interceptor';
import { NextBestActionListComponent } from './components/leads/next-best-action/next-best-action-list.component';
import { LeadListOverviewComponent } from './components/leads/lead-list-new/lead-list-overview.component';
import { LeadListDetailViewComponent } from './components/leads/lead-list-new/lead-list-detail-view.component';
import { LeadListNewComponent } from './components/leads/lead-list-new/lead-list-new.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { environment } from '../../../lib/environments/environment';

registerLocaleData(localeDe);
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PartnerUsersComponent,
    PartnerBillsComponent,
    PartnerBillDeleteModalComponent,
    LeadListComponent,
    LeadListOverviewComponent,
    LeadListDetailViewComponent,
    LeadListNewComponent,
    ChanceListComponent,
    TargetingListComponent,
    EditTargetingDetailModalComponent,
    PartnerLeadOfferValueComponent,
    PartnerBillFileDeleteComponent,
    TaskBoardComponent,
    DashboardComponent,
    PartnerBillFormComponent,
    LeadEditComponent,
    PartnerLeadBackendComponent,
    NextBestActionListComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterOutlet,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgForOf,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:3000',
    }),
    NgIf,
    MatMenuModule,
    MatExpansionModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
