export class Address {
  id: number = 0;
  type: number = 1;
  company_name: string = '';
  salutation: string = '';
  first_name: string = '';
  last_name: string = '';
  street: string = '';
  postcode: string = '';
  city: string = '';
  district: string = '';
  federal_state: string = '';
  land: string = '';
  phone2: string = '';
  fax: string = '';
  phone: string = '';
  email: string = '';
  country_code: string = '';
  email_confirmed_hash: string = '';
  name: string = '';
  address: string = '';
  ust_id: string = '';
  lat: number = 0;
  lng: number = 0;
  osm_id: number = 0;
  lead_id: number = 0;
  partner_lead_id: number = 0;
  partner_id: number = 0;

  phone_is_valid: string = '';
  phone_checked_at: string = '';
  phone_provider: string = '';
  phone_type: string = '';
  phone2_is_valid: string = '';
  phone2_checked_at: string = '';
  phone2_provider: string = '';
  phone2_type: string = '';
  email_is_valid: string = '';
  email_checked_at: string = '';

  constructor(address: Address | {}) {
    // Initialize any other properties or perform additional setup if needed
    Object.assign(this, address);
  }
}
