import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TableComponent } from '@lib/components/table/table.component';
import { FilterSetting } from '@lib/models/filter-setting';
import { SelectList } from '@lib/models/select-list';
import { PartnerLead } from '@lib/models/partner-lead';
import { Reminder } from '@lib/models/reminder';
import { NewSelectOption } from '@lib/models/new-select-option';
import { HttpResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ModalFormFieldType, PartnerLeadStatus } from '@lib/enum-collection';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { NewFilterService } from '@lib/services/filter/new-filter.service';
import { LeadStateService } from '../../../services/lead-state.service';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { ReminderService } from '@lib/services/reminder.service';
import { SelectService } from '@lib/services/select.service';
import { LeadListViewService } from '../../../services/lead-list-view.service';
import { AddressService } from '@lib/services/address/address.service';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { User } from '@lib/models/user';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { AuthService } from '@lib/services/auth.service';
import { Comment } from '@lib/models/comment';
import { DateHelper } from '@lib/helpers/date-helper';
import { AnalyticsService } from '@lib/services/analytics.service';
import { LeadListNewComponent } from './lead-list-new.component';
import { FormField, ModalInputData } from '@lib/models/modal';
import { CreateFormGroupHelper } from '@lib/helpers/create-form-group-helper';

@Component({
  selector: 'app-lead-list-overview',
  templateUrl: './lead-list-overview.component.html',
  styleUrls: ['./lead-list-overview.component.scss'],
})
export class LeadListOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  public filterBarSettings: FilterSetting[] = [];
  public filterSubscription: Subscription = new Subscription();
  private readonly destroy$ = new Subject<void>();
  private readonly refreshTrigger$ = new BehaviorSubject<void>(undefined);
  authUser: User | PartnerUser | null = null;

  useNewView$ = this.leadListViewService.useNewView$;
  statusOptions$ = this.loadStatusOptions();
  partnerScore$ = this.loadPartnerScore();
  hasMissingBills$: Observable<boolean> =
    this.partnerLeadService.hasMissingBills();

  filter = {
    status: null,
    lead_type_id: null,
    lead_details: null,
    partner_user_id: null,
    lead_response: null,
  };

  constructor(
    private readonly snackbarService: SnackBarService,
    private readonly filterService: NewFilterService,
    private readonly leadStateService: LeadStateService,
    private readonly partnerLeadService: PartnerLeadService,
    private readonly reminderService: ReminderService,
    private readonly authService: AuthService,
    private readonly selectService: SelectService,
    private readonly leadListViewService: LeadListViewService,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
    public readonly addressService: AddressService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.initFilter();
    this.initializeLeadUpdates();
    this.initializeRefreshTrigger();

    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.authUser = user;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.filterSubscription.unsubscribe();
  }

  private initializeLeadUpdates(): void {
    this.leadStateService.leadUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.tableComponent?.reloadData();
      });
  }

  private initializeRefreshTrigger(): void {
    this.refreshTrigger$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.tableComponent?.reloadData();
    });
  }

  toggleView() {
    this.analyticsService.eventEmitter(
      'button_click',
      'actions',
      'click',
      'Lead List Toggle',
    );

    this.leadListViewService.toggleView();
  }

  async onLeadSelected(lead: PartnerLead) {
    const leadListComponent = this.injector.get(LeadListNewComponent);
    const canProceed = await leadListComponent.onLeadSelectionChange(lead.id);

    if (!canProceed) {
      return;
    }
  }

  initFilter(disabledFilter = false): void {
    this.filterSubscription = this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.LeadResponse,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Rückmeldung',
      }),
      new FilterSetting({
        filter: SelectList.PartnerLeadStatus,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Status',
      }),
      new FilterSetting({
        filter: SelectList.PartnerUser,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Benutzer',
      }),
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Leadart',
      }),
      new FilterSetting({
        filter: SelectList.PartnerLeadDetail,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Details',
      }),
    ];

    this.filterService.getFilter();
  }

  private loadStatusOptions(): Observable<NewSelectOption[]> {
    return this.selectService.getSelectOptionsBySelectList(
      SelectList.PartnerLeadStatus,
    );
  }

  private loadPartnerScore(): Observable<number> {
    return this.partnerLeadService.getPartnerLeadScoreByAuthenticatedPartnerUser();
  }

  setFilter(filter: any): void {
    const filterData = filter?.filter ?? filter;

    this.filter = {
      ...this.filter,
      status: filterData?.[SelectList.PartnerLeadStatus],
      lead_type_id: filterData?.[SelectList.LeadType],
      lead_details: filterData?.[SelectList.PartnerLeadDetail],
      partner_user_id: filterData?.[SelectList.PartnerUser],
      lead_response: filterData?.[SelectList.LeadResponse],
    };
  }

  onDisplayedDataChanged(partnerLeads: PartnerLead[]) {
    partnerLeads.forEach((lead) => {
      if (lead.reminders?.length > 0) {
        lead.reminders.sort(
          (a, b) =>
            new Date(a.reminder_date).getTime() -
            new Date(b.reminder_date).getTime(),
        );
      }
    });

    this.leadStateService.updatePartnerLeads(partnerLeads);
  }

  getReminderIcon(reminder: Reminder): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const reminderDate = new Date(reminder.reminder_date);
    reminderDate.setHours(0, 0, 0, 0);

    if (reminderDate.getTime() === today.getTime()) {
      return 'notification_important';
    } else if (reminderDate < today) {
      return 'warning';
    } else {
      return 'notifications_active';
    }
  }

  getBadgeClass(reminder: Reminder): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const reminderDate = new Date(reminder.reminder_date);
    reminderDate.setHours(0, 0, 0, 0);

    if (reminderDate.getTime() === today.getTime()) {
      return 'reminder-today';
    } else if (reminderDate < today) {
      return 'reminder-overdue';
    } else {
      return 'reminder-future';
    }
  }

  formatDate(date: Date | string | undefined): string {
    return this.reminderService.formatDate(date);
  }

  setLeadStatus(newStatus: NewSelectOption, partnerLead: PartnerLead): void {
    const newStatusId = newStatus.value;

    this.partnerLeadService.handleStatusChange(
      newStatusId,
      partnerLead,
      this.authUser,
      (updatedLead) => {
        this.leadStateService.updateSingleLead(updatedLead);

        this.tableComponent?.reloadData();
      },
      () => {
        this.tableComponent?.reloadData();
      },
    );
  }

  getStatusClass(statusId: number): string {
    switch (statusId) {
      case PartnerLeadStatus.Neu:
        return 'status-new';
      case PartnerLeadStatus['Kontakt hergestellt']:
        return 'status-contact';
      case PartnerLeadStatus['Angebot versendet & Wiedervorlage']:
        return 'status-offer';
      case PartnerLeadStatus.Verloren:
        return 'status-lost';
      case PartnerLeadStatus['Auftrag erhalten']:
      case PartnerLeadStatus['Kundenseitige Auftragsmeldung']:
      case PartnerLeadStatus['Auftrag erhalten & Projekt beendet']:
        return 'status-success';
      default:
        return 'status-default';
    }
  }

  onExportExcel(): void {
    const formFields: FormField[] = [
      {
        type: ModalFormFieldType.date,
        label: 'Von',
        name: 'dateFrom',
        validation: { required: true },
      },
      {
        type: ModalFormFieldType.date,
        label: 'Bis',
        name: 'dateTo',
        validation: { required: true },
      },
    ];

    const inputData: ModalInputData = {
      headline: 'Zeitraum für Excel-Export wählen',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      acceptButtonText: 'Exportieren',
      declineButtonText: 'Abbrechen',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse) return;

        const filterWithDates = {
          ...this.filter,
          dateFrom: modalResponse.dateFrom,
          dateTo: modalResponse.dateTo,
        };

        this.partnerLeadService
          .export({
            filter: filterWithDates,
            export: true,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: HttpResponse<Blob | null>) => {
              if (response.body) {
                this.saveExcelFile(response.body);
              } else {
                this.snackbarService.openSnackBar(
                  'Oops, etwas schief gelaufen',
                  'danger',
                );
              }
            },
            error: (error) => console.error('Error fetching Excel data', error),
          });
      });
  }

  private handleStatusUpdateError(): void {
    this.snackbarService.openSnackBar(
      'Status konnte nicht aktualisiert werden',
      'warn',
    );
  }

  saveExcelFile(blob: Blob) {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = 'export.xlsx';
    link.click();
  }

  private addReminder(partnerLead: PartnerLead): void {
    const inputData = this.reminderService.getReminderModalInputData(
      undefined,
      this.authUser,
      undefined,
    );

    this.dialog
      .open(ModalComponent, { disableClose: true, data: inputData })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalResponse) =>
          this.processReminderFromResponse(modalResponse, partnerLead),
        ),
        tap(
          () =>
            this.snackbarService.openSnackBar(
              'Erinnerung gespeichert',
              'success',
            ),
          () =>
            this.snackbarService.openSnackBar(
              'Fehler beim Speichern der Erinnerung',
              'warn',
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.tableComponent?.reloadData();
      });
  }

  private processReminderFromResponse(
    modalResponse: any,
    partnerLead: PartnerLead,
  ): Observable<Reminder> {
    const reminder = this.reminderService.createOrUpdateReminderObject(
      modalResponse,
      undefined,
    );

    reminder.partner_lead_id = partnerLead.id;
    reminder.visible_in_salesrunner = true;

    return this.reminderService.createReminder(reminder).pipe(
      filter((reminderRet: Reminder) => !!reminderRet?.id),
      switchMap((reminderRet: Reminder) =>
        this.reminderService.handleCommentCreation(
          reminderRet,
          modalResponse,
          this.authUser,
        ),
      ),
      map((result) => {
        if (result instanceof Comment) {
          return reminder;
        }
        return result as Reminder;
      }),
    );
  }

  isBillOverdue(partnerLead: PartnerLead): boolean {
    if (
      !partnerLead.status ||
      partnerLead.status.option_id !== PartnerLeadStatus['Auftrag erhalten']
    ) {
      return false;
    }

    if (!partnerLead.bills || partnerLead.bills.length === 0) {
      return true;
    }

    const lastBillDate = this.getLastBillDate(partnerLead.bills);
    return lastBillDate < DateHelper.getFirstDayOfLastMonth();
  }

  getBillWarningTooltip(partnerLead: PartnerLead): string {
    if (!partnerLead.bills || partnerLead.bills.length === 0) {
      return 'Bitte Rechnung eintragen - Noch keine Rechnung vorhanden';
    }

    const lastBillDate = this.getLastBillDate(partnerLead.bills);
    return `Bitte Rechnung eintragen - Letzte Rechnung: ${DateHelper.getFormattedDate(
      lastBillDate,
    )}`;
  }

  private getLastBillDate(bills: Array<{ bill_date: string }>): Date {
    return bills.reduce((latest: Date, bill) => {
      const billDate = new Date(bill.bill_date);
      return billDate > latest ? billDate : latest;
    }, new Date(0));
  }

  getLeadResponseClass(partnerLead: any): string {
    if (!partnerLead.lead?.lead_response) return '';

    switch (partnerLead.lead.lead_response) {
      case 4:
        return 'response-danger';
      case 1:
        if (
          partnerLead.lead.lead_response_success_partner_lead_id ===
          partnerLead.id
        ) {
          return 'response-success';
        } else {
          return 'response-danger';
        }
      case 2:
        return 'response-danger';
      case 3:
        return 'response-warning';
      default:
        return 'response-default';
    }
  }

  getLeadResponseIcon(partnerLead: any): string {
    if (!partnerLead.lead?.lead_response) return 'info';

    switch (partnerLead.lead.lead_response) {
      case 4:
        return 'cancel';
      case 3:
        return 'schedule';
      case 1:
        if (
          partnerLead.lead.lead_response_success_partner_lead_id ===
          partnerLead.id
        ) {
          return 'check';
        } else {
          return 'cancel_presentation';
        }
      case 2:
        return 'cancel_presentation';
      default:
        return 'info';
    }
  }

  getLeadResponseTooltip(partnerLead: any): string {
    if (!partnerLead.lead?.lead_response) return '';

    let statusText = '';
    switch (partnerLead.lead.lead_response) {
      case 4:
        statusText = 'Rückmeldung vom Lead: Projekt abgesagt';
        break;
      case 3:
        statusText = 'Rückmeldung vom Lead: Noch nicht entschieden';
        break;
      case 2:
        statusText = 'Rückmeldung vom Lead: Anderer Anbieter';
        break;
      case 1:
        if (
          partnerLead.lead.lead_response_success_partner_lead_id ===
          partnerLead.id
        ) {
          statusText = 'Auftragsmeldung vom Lead';
        } else {
          statusText = 'Rückmeldung vom Lead: Anderer Anbieter';
        }
        break;
    }

    return `${statusText}\n${this.formatDate(
      partnerLead.lead.lead_response_at,
    )}`;
  }
  protected readonly SelectList = SelectList;
}
