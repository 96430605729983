<div *ngIf="document" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <div
      class="custom-badge badge-red"
      *ngIf="
        ApplicationHelper.applicationName === Application.Leadmanager &&
        getFeedSalesrunnerVisibilityHint() &&
        lead?.status != 3
      "
    >
      <span [innerHTML]="getFeedSalesrunnerVisibilityHint()"></span>
    </div>
  </div>

  <div class="documents-container">
    <!-- Hauptdokument -->
    <div class="document-item">
      <a
        href="javascript:void(0)"
        (click)="editFile(document)"
        class="document-link"
      >
        <div
          class="icon-container"
          [innerHTML]="getFileIcon(document.file_suffix)"
        ></div>
        <div class="file-details">
          <span class="file-name">{{ document.original_file_name }}</span>
          <span class="file-size">{{ formatFileSize(document.size) }}</span>
        </div>
      </a>
    </div>

    <!-- Verknüpfte Dokumente -->
    <div
      *ngFor="let relatedDoc of document.related_documents"
      class="document-item"
    >
      <a
        href="javascript:void(0)"
        (click)="editFile(relatedDoc)"
        class="document-link"
      >
        <div
          class="icon-container"
          [innerHTML]="getFileIcon(relatedDoc.file_suffix)"
        ></div>
        <div class="file-details">
          <span class="file-name">{{ relatedDoc.original_file_name }}</span>
          <span class="file-size">{{ formatFileSize(relatedDoc.size) }}</span>
        </div>
      </a>
    </div>
  </div>
</div>
