<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <span class="comment-edited" *ngIf="comment?.edited_at">
    <em>bearbeitet</em>
  </span>

  <div
    class="custom-badge badge-red"
    *ngIf="
      ApplicationHelper.applicationName === Application.Leadmanager &&
      getFeedSalesrunnerVisibilityHint() &&
      lead?.status != 3
    "
  >
    <span [innerHTML]="getFeedSalesrunnerVisibilityHint()"></span>
  </div>
</div>
<p style="white-space: pre-line">{{ comment.comment }}</p>
