<h1 mat-dialog-title [innerHTML]="headlineText"></h1>

<mat-dialog-content
  [formGroup]="modalForm"
  class="mat-typography"
  (keydown.enter)="confirm()"
  (keydown.escape)="close()"
>
  <div
    class="body-text"
    [style.white-space]="'pre-line'"
    [innerHTML]="bodyText"
  ></div>
  <div *ngFor="let field of formFields">
    <div
      fxLayout="column"
      fxLayoutAlign="space-around stretch"
      fxLayoutGap="10px"
    >
      <ng-container *ngIf="isVisible(field)">
        <ng-container [ngSwitch]="field.type">
          <mat-form-field *ngSwitchCase="'text'" appearance="fill">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput [formControlName]="field.name" />
            <mat-error *ngIf="getErrorMessage(field)">
              {{ getErrorMessage(field) }}
            </mat-error>
          </mat-form-field>

          <mat-checkbox
            *ngSwitchCase="'checkbox'"
            [formControlName]="field.name"
          >
            {{ field.label }}
          </mat-checkbox>

          <mat-form-field *ngSwitchCase="'textarea'" appearance="fill">
            <mat-label>{{ field.label }}</mat-label>
            <textarea matInput [formControlName]="field.name"></textarea>
            <mat-error *ngIf="getErrorMessage(field)">
              {{ getErrorMessage(field) }}
            </mat-error>
          </mat-form-field>

          <mat-radio-group
            *ngSwitchCase="'radio'"
            [formControlName]="field.name"
          >
            <mat-label>{{ field.label }}</mat-label>
            <mat-radio-button
              *ngFor="let option of field.options"
              [value]="option.value"
            >
              {{ option.label }}
            </mat-radio-button>
            <mat-error *ngIf="getErrorMessage(field)">
              {{ getErrorMessage(field) }}
            </mat-error>
          </mat-radio-group>

          <mat-form-field *ngSwitchCase="'select'" appearance="fill">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select [formControlName]="field.name">
              <mat-option
                *ngFor="let option of field.options"
                [value]="option.value"
              >
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'date'" appearance="fill">
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [formControlName]="field.name"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="getErrorMessage(field)">
              {{ getErrorMessage(field) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'time'" appearance="fill">
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [format]="24"
              [formControlName]="field.name"
              [ngxMatTimepicker]="timePicker"
              readonly
            />
            <mat-icon matSuffix (click)="timePicker.open()">
              watch_later
            </mat-icon>
          </mat-form-field>
          <ngx-mat-timepicker #timePicker></ngx-mat-timepicker>
        </ng-container>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="10px"
>
  <button
    class="btn btn-success btn-danger"
    (click)="close()"
    *ngIf="showDeclineButton"
  >
    {{ declineButtonText }}
  </button>
  <button class="btn btn-success btn-success" (click)="confirm()">
    {{ acceptButtonText }}
  </button>
</mat-dialog-actions>
