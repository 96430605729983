import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private environmentService: EnvironmentService) {}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = '',
  ) {
    if (typeof gtag === 'undefined') return;

    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
    });
  }

  private waitForGtag(): Promise<void> {
    return new Promise((resolve) => {
      if (typeof gtag !== 'undefined') {
        resolve();
      } else {
        setTimeout(() => this.waitForGtag().then(resolve), 100);
      }
    });
  }

  public async pageView(url: string) {
    if (this.environmentService.production) {
      // Da das Tag nicht über die index, sondern über die app component gesetzt wird, muss hier gewartet werden, bis es verfügbar ist. Sonst kommt es zu Fehlern
      await this.waitForGtag();
      gtag('set', {
        page_path: url,
        page_title: document.title,
      });
      gtag('event', 'page_view');
    }
  }
}
