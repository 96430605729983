import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  title = 'Umsatz';
  private newLabel? = 'New label';
  @Input() labels: any = [];
  @Input() datasets: any = [];
  containsData: boolean = false;
  lineChartData: ChartConfiguration['data'] = { datasets: [], labels: [] };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    this.lineChartData = {
      datasets: this.datasets,
      labels: this.labels,
    };

    this.containsData = !!this.lineChartData?.datasets[0]?.data?.some(
      (value) => value !== 0,
    );
  }

  // @ts-ignore
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'Umsatz (€)',
        },
        ticks: {
          callback: function (value) {
            return value.toLocaleString() + ' €';
          },
        },
      },
      percentage: {
        position: 'right',
        title: {
          display: true,
          text: 'Quote (%)',
        },
        min: 0,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.dataset.yAxisID === 'percentage') {
              label += context.parsed.y.toFixed(1) + '%';
            } else {
              label += context.parsed.y.toLocaleString() + ' €';
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  public lineChartType: ChartType = 'line';

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {}
}
